<template>
	<div class="reletive">
		<swiper
			class="swiper relative"
			:options="swiperOption"
			v-if="images.length > 0"
		>
			<div
				v-for="image of images"
				:key="image.src"
				class="swiper-slide carousel_image_wrapper "
			>
				<img
					v-if="image.src"
					class="h-full object-cover"
					:src="image.src"
					alt=""
				/>
			</div>
		</swiper>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			class="photo_arrow mr-4 cursor-pointer absolute z-50 transform -translate-y-300 translate-x-10 mx640:-translate-y-200 mx640:translate-x-2"
			width="35"
			height="35"
			viewBox="0 0 35 35"
			@click="prevSlide"
		>
			<g fill="" fill-rule="evenodd">
				<g>
					<g transform="translate(-734 -2905) translate(734 2905)">
						<circle cx="17.5" cy="17.5" r="17.5" fill="" />
						<g>
							<g>
								<g>
									<path
										d="M6 10L12 16 18 10"
										transform="translate(5.5 5.5) rotate(180 12 12) rotate(-90 12 12)"
										class="photo_arrow_stroke"
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
		<svg
			@click="nextSlide"
			class="photo_arrow cursor-pointer absolute z-50 transform -translate-y-300 -translate-x-16 right-0 mx640:-translate-y-200 mx640:-translate-x-2"
			xmlns="http://www.w3.org/2000/svg"
			width="35"
			height="35"
			viewBox="0 0 35 35"
		>
			<g fill="" fill-rule="evenodd">
				<g>
					<g transform="translate(-779 -2905) translate(779 2905)">
						<circle cx="17.5" cy="17.5" r="17.5" fill="" />
						<g>
							<g>
								<path
									class="photo_arrow_stroke"
									d="M6 10L12 16 18 10"
									transform="translate(5.5 5.5) rotate(-90 12 12)"
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	</div>
</template>
<script>
	import { Swiper } from "vue-awesome-swiper";
	import "swiper/css/swiper.css";
	/*
	var image_0 = require("@/assets/photo_carousel_test/0.jpeg");
	var image_1 = require("@/assets/photo_carousel_test/1.jpeg");
	var image_2 = require("@/assets/photo_carousel_test/2.jpeg");
	var image_3 = require("@/assets/photo_carousel_test/3.jpeg");
	var image_4 = require("@/assets/photo_carousel_test/4.jpeg");
	var image_5 = require("@/assets/photo_carousel_test/5.jpeg");
	var image_6 = require("@/assets/photo_carousel_test/6.jpeg");

	*/
	export default {
		props: ["obj"],
		data: () => {
			return {
				/*
				images: [
					{ src: image_0, caption: "Castros celtas, Vigo, Spain" },
					{ src: image_1, caption: "Castros celtas, Vigo, Spain" },
					{ src: image_2, caption: "Castillo, Vigo, Spain" },
					{ src: image_3, caption: "Ría, Vigo, Spain" },
					{ src: image_4, caption: "Castros celtas, Vigo, Spain" },
					{ src: image_5, caption: "Castillo, Vigo, Spain" },
					{ src: image_6, caption: "Ría, Vigo, Spain" },
				],
				*/
				images: [],
				swiperOption: {
					spaceBetween: 0,
					effect: "slide",
					centeredSlides: true,
					centeredSlidesBounds: true,
					grabCursor: true,
					loop: true,
					speed: 700,
					slidesPerView: "auto",
				},
				test: null,
			};
		},
		mounted() {
			//console.log(this.$props.obj)
			this.images = [];
			var thisVue = this;
			this.$props.obj.forEach(function(item, index) {
				thisVue.images.push({ src: item.md, caption: "" });
			});
			//
		},
		components: {
			Swiper,
		},
		methods: {
			nextSlide() {
				const swiper = document.querySelector(".swiper-container").swiper;
				swiper.slideNext();
			},
			prevSlide() {
				const swiper = document.querySelector(".swiper-container").swiper;
				swiper.slidePrev();
			},
		},
	};
</script>

<style scoped>
	.carousel_image_wrapper {
		height: 600px;
		width: auto;
	}
	.photo_arrow {
		fill: #faecec;
		transition: fill 0.4s ease;
	}
	.photo_arrow:hover {
		fill: #d6444a;
	}
	.photo_arrow_stroke {
		stroke: #383b41;
	}
	.photo_arrow:hover .photo_arrow_stroke {
		stroke: #fff;
	}
	@media screen and (max-width: 640px) {
		.carousel_image_wrapper {
			height: 400px;
		}
	}
</style>
