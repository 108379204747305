<template>
	<div class="grid grid-cols-2 pb-12 gap-6 max-w-sm">
		<router-link :to="'/' + route_team + '/' + card.slug">
			<div
				v-if="card.image"
				class="card-image-wrapper overflow-hidden overflow-x-hidden"
			>
				<img
					class="w-full transform hover:scale-110 transition duration-700 ease-in-out"
					:src="card.image.file"
					alt=""
				/>
			</div>
		</router-link>
		<div class="grid grid-rows-2">
			<div
				class="text-verbena font-tiempos_r text-24 mx900:text-3 mx640:text-5"
			>
				<h2>{{ card.first_name }}</h2>
				<h2>{{ card.last_name }}</h2>
			</div>
			<div class="flex items-end pb-4">
				<a
					class="mr-4"
					v-if="card.linkedin"
					:href="card.linkedin"
					target="blank"
				>
					<svg
						class="team_icon"
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						viewBox="0 0 20 20"
					>
						<g fill="" fill-rule="evenodd">
							<g fill="">
								<g>
									<path
										d="M4.15 2.083c0 1.151-.925 2.084-2.067 2.084-1.141 0-2.066-.933-2.066-2.084C.017.933.942 0 2.083 0 3.225 0 4.15.933 4.15 2.083zm.017 3.75H0v13.334h4.167V5.833zm6.651 0h-4.14v13.334h4.141v-7c0-3.891 5.024-4.21 5.024 0v7H20v-8.443c0-6.567-7.435-6.327-9.182-3.095V5.833z"
										transform="translate(-729 -2008) translate(729 2008)"
									/>
								</g>
							</g>
						</g>
					</svg>
				</a>
				<a v-if="card.email" :href="'mailto:' + card.email"
					><svg
						xmlns="http://www.w3.org/2000/svg"
						class="team_icon"
						width="25"
						height="17"
						viewBox="0 0 25 17"
					>
						<g fill="" fill-rule="evenodd">
							<g fill="">
								<g>
									<path
										d="M0 15.4L7.95 7.45 0 .775zM16.55 7.45L24.5 15.4 24.5.775zM12.25 11.025L8.825 8.175.9 16.1 23.6 16.1 15.675 8.175z"
										transform="translate(-338 -2012) translate(338 2012)"
									/>
									<path
										d="M0.85 0L12.25 9.55 23.65 0z"
										transform="translate(-338 -2012) translate(338 2012)"
									/>
								</g>
							</g>
						</g></svg
				></a>
			</div>
		</div>
	</div>
</template>

<script>
	import { globals } from "@/modules/globals";
	export default {
		name: "TeamCard",
		props: ["card"],
		data() {
			return {
				route_team: globals.navigation["who-we-are"].slug,
			};
		},
	};
</script>

<style>
	@media only screen and (min-width: 1500px) {
		.card-image-wrapper {
			max-width: 200px;
		}
	}
	.team_icon:hover {
		fill: #ff7e4b;
	}
	.team_icon {
		fill: #f7dadb;
		transition: fill 0.3s ease;
	}
</style>
