<template>
	<div>
		<div v-if="show === 'yes'">
			<div class="mode-center pt-170 pb-20">
				<div class="anime-hover">
					<router-link
						class="text-xl font-inter text-pine py-2 mx640:text-3.5"
						:to="'/' + route_team"
						@click.native.prevent="navigate_back_to_team"
						>{{ btn_team_back }}</router-link
					>
				</div>
				<div class="w-93% ml-20 mx900:ml-0 mx480:w-full">
					<div
						class="grid grid-cols-8 pt-100 text-pine pb-10 mx480:grid-cols-1"
					>
						<div v-if="singleTeam.image" class="col-span-3 mx480:mb-6">
							<img class="mx1280:w-full" :src="singleTeam.image.sm" alt="" />
						</div>
						<div
							class="flex flex-col justify-between col-span-4 col-start-5 mx480:col-start-1"
						>
							<h1 class="h1-text mx1280:text-5 mx480:text-9">
								{{ singleTeam.first_name }} {{ singleTeam.last_name }}
							</h1>
							<p class="text-30 font-tiempos_r mx1280:text-3 mx480:text-6">
								{{ singleTeam.quote }}
							</p>
						</div>
					</div>
					<div class="flex items-end pb-6">
						<a
							class="mr-8"
							v-if="singleTeam.linkedin"
							:href="singleTeam.linkedin"
							target="blank"
							><svg
								xmlns="http://www.w3.org/2000/svg"
								class="single_icon"
								width="20"
								height="20"
								viewBox="0 0 20 20"
							>
								<g fill="" fill-rule="evenodd">
									<g fill="">
										<g>
											<path
												d="M4.15 2.083c0 1.151-.925 2.084-2.067 2.084-1.141 0-2.066-.933-2.066-2.084C.017.933.942 0 2.083 0 3.225 0 4.15.933 4.15 2.083zm.017 3.75H0v13.334h4.167V5.833zm6.651 0h-4.14v13.334h4.141v-7c0-3.891 5.024-4.21 5.024 0v7H20v-8.443c0-6.567-7.435-6.327-9.182-3.095V5.833z"
												transform="translate(-729 -2008) translate(729 2008)"
											/>
										</g>
									</g>
								</g>
							</svg>
						</a>
						<a v-if="singleTeam.email" :href="'mailto:' + singleTeam.email"
							><svg
								xmlns="http://www.w3.org/2000/svg"
								class="single_icon"
								width="25"
								height="17"
								viewBox="0 0 25 17"
							>
								<g fill="" fill-rule="evenodd">
									<g fill="">
										<g>
											<path
												d="M0 15.4L7.95 7.45 0 .775zM16.55 7.45L24.5 15.4 24.5.775zM12.25 11.025L8.825 8.175.9 16.1 23.6 16.1 15.675 8.175z"
												transform="translate(-338 -2012) translate(338 2012)"
											/>
											<path
												d="M0.85 0L12.25 9.55 23.65 0z"
												transform="translate(-338 -2012) translate(338 2012)"
											/>
										</g>
									</g>
								</g>
							</svg>
						</a>
					</div>
				</div>
			</div>
			<div class="bg-shea">
				<div class="mode-center">
					<div
						v-html="singleTeam.about"
						class="rich-text team-rich-text mr-100 ml-auto w-3/5 font-tiempos_r text-pine mx1080:w-full pb-20 pt-140"
					></div>
				</div>
			</div>
			<GetInTouch />
		</div>
		<page_404 v-if="show === '404'" />
	</div>
</template>

<script>
	import data from "@/data.json";
	import GetInTouch from "@/components/ui/GetInTouch.vue";
	import { globals } from "@/modules/globals";
	import page_404 from "@/components/page_404.vue";
	export default {
		name: "TeamSingle",
		components: { GetInTouch, page_404 },
		data() {
			return {
				singleTeam: {},
				show: null,
				tempData: data,
				slug: this.$route.params.slug,
				image: "",
				route_team: globals.navigation["who-we-are"].slug,
				btn_team_back: globals.microcopy.btn_team_back[globals.language],
			};
		},
		beforeRouteEnter(to, from, next) {
			let slug = to.params.slug;
			let json_file =
				"/json/team/team_" +
				globals.language +
				"__" +
				slug +
				".json?v=" +
				globals.content_version;
			fetch(json_file)
				.then((response) => response.json())
				.then(function(data) {
					next((vm) => vm.setData(data));
				})
				.catch((error) => {
					console.error("FETCH Error:", error);
					next((vm) => vm.notFound());
				});
		},
		methods: {
			setData(data) {
				this.show = "yes";
				this.singleTeam = data;
			},
			notFound() {
				this.show = "404";
			},
			navigate_back_to_team() {
				if (window.history.length > 3) {
					window.history.back();
				} else {
					this.$router.push({ name: "team" });
				}
			},
		},
	};
</script>

<style>
	.single_icon:hover {
		fill: #ff7e4b;
	}
	.single_icon {
		fill: #193e3a;
		transition: fill 0.3s ease;
	}
</style>
