<template>
	<div class="">
		<div class="mode-center pt-170 mx640:pt-140 mx640:pb-100">
			<div class="w-full">
				<div
					class="anime-hover grid grid-cols-5 gap-24 pb-32 mx900:grid-cols-1 mx900:pb-16 mx900:gap-0"
				>
					<div class="mx900:pb-10 col-span-2">
						<img
							class="w-full"
							v-if="data_hero.image"
							:src="data_hero.image.md"
						/>
						<img
							v-if="data_hero.image == null"
							class="mt-120 mx900:mt-0 mx640:w-1/2 mx900:w-9/12 mx640:mx-auto"
							src="@/assets/logo-huge.svg"
							alt=""
						/>
					</div>
					<div class="col-span-3 text-pine">
						<h1
							class="h1-text pb-8 mx640:text-9 mx900:text-6"
							v-if="data_hero.title"
						>
							{{ data_hero.title }}
						</h1>
						<div
							class="text-24 mx1280:text-2.5 mx640:text-4.5 font-tiempos_r"
							v-if="data_hero.text"
							v-html="data_hero.text"
						></div>
					</div>
				</div>
				<div
					class="anime-hover grid grid-cols-2 gap-8 pb-20 text-pine mx900:grid-cols-1 mx640:pb-0"
					v-if="data_columns_list[0]"
				>
					<div class="">
						<div class="pb-10">
							<img
								class="mx640:w-1/4 mx900:w-1/6"
								src="@/assets/about/1.svg"
								alt=""
							/>
						</div>
						<h1
							class="h1-text pb-6 mx640:text-9"
							v-if="data_columns_list[0].title"
						>
							{{ data_columns_list[0].title }}
						</h1>
						<div
							class="text-22 mx1280:text-2.5 mx640:text-4 font-tiempos_r mx900:text-3"
							v-if="data_columns_list[0].description"
							v-html="data_columns_list[0].description"
						></div>
					</div>
					<div class="">
						<div class="pb-10">
							<img
								class="mx640:w-1/4 mx900:w-1/6"
								src="@/assets/about/2.svg"
								alt=""
							/>
						</div>
						<h1
							class="h1-text pb-6 mx640:text-9"
							v-if="data_columns_list[1].title"
						>
							{{ data_columns_list[1].title }}
						</h1>
						<div
							class="text-22 mx1280:text-2.5 mx640:text-4 font-tiempos_r mx900:text-3"
							v-if="data_columns_list[1].description"
							v-html="data_columns_list[1].description"
						></div>
					</div>
				</div>
			</div>
		</div>
		<div class="carousel__wrapper">
			<PhotoCarousel :obj="data_carrousel" v-if="carrousel_loaded" />
		</div>
		<div class="bg-pine">
			<div class="mode-center pt-140">
				<h2 class="font-tiempos_r text-40 text-white pb-10 mx640:text-6">
					{{ txt_partners }}
				</h2>
				<div
					class="grid grid-cols-3 gap-10 pb-6 mx1080:grid-cols-2 mx640:grid-cols-1"
				>
					<TeamCard
						v-for="card in teamPageData.partners"
						:key="card.id"
						:card="card"
					/>
				</div>
				<h2 class="font-tiempos_r text-40 text-white pb-10 mx640:text-6">
					{{ txt_investment_team }}
				</h2>
				<div
					class="grid grid-cols-3 gap-10 pb-32 mx1080:grid-cols-2 mx640:grid-cols-1"
				>
					<TeamCard
						v-for="card in teamPageData.team"
						:key="card.id"
						:card="card"
					/>
				</div>
			</div>
		</div>
		<div class="bg-shea">
			<div class="mode-center">
				<div
					class="w-11/12 text-pine grid grid-cols-8 gap-8 pt-120 mx720:w-full mx640:pt-16 pb-120 mx640:grid-cols-1"
				>
					<h1
						class="h1-text col-span-3 mx640:text-9 mn1080:w-1/2"
						v-if="data_work.title"
					>
						{{ data_work.title }}
					</h1>
					<div class="col-span-5 anime-hover">
						<div
							class="font-tiempos_r section_text section_text_bread anime-hover mb-12"
							v-if="data_work.text"
							v-html="data_work.text"
						></div>

						<router-link
							:to="{
								path: data_work.button_link,
								query: { topic: 'vacancies' },
							}"
							class="font-inter text-lg mx640:4vw"
							v-if="data_work.button_link && data_work.button_text"
						>
							{{ data_work.button_text }}
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<GetInTouch class="hidden" />
	</div>
</template>

<script>
	import TeamCard from "@/components/ui/TeamCard.vue";
	import GetInTouch from "@/components/ui/GetInTouch.vue";
	import PhotoCarousel from "@/components/ui/PhotoCarousel.vue";
	import data from "@/data.json";
	import { globals } from "@/modules/globals";
	export default {
		name: "Team",
		components: { TeamCard, GetInTouch, PhotoCarousel },
		data() {
			return {
				teamPageData: {
					partners: [],
					team: [],
				},
				tempData: data,
				data_hero: {},
				data_columns_list: {},
				txt_partners: globals.microcopy.txt_partners[globals.language],
				txt_investment_team:
					globals.microcopy.txt_investment_team[globals.language],
				data_carrousel: null,
				carrousel_loaded: false,
				data_work: {},
			};
		},
		beforeRouteEnter(to, from, next) {
			let dataFiles = 2;

			let teamData = null;
			let json_file_team =
				"/json/team/team_" +
				globals.language +
				".json?v=" +
				globals.content_version;
			fetch(json_file_team)
				.then((response) => response.json())
				.then(function(data) {
					teamData = data;
					afterJsonLoaded();
				})
				.catch((error) => {
					console.error("FETCH Error:", error);
				});

			let sectionsData = null;
			let json_file_sections =
				"/json/sections/sections_who-we-are_" +
				globals.language +
				".json?v=" +
				globals.content_version;
			fetch(json_file_sections)
				.then((response) => response.json())
				.then(function(data) {
					sectionsData = data;
					afterJsonLoaded();
				})
				.catch((error) => {
					console.error("FETCH Error:", error);
				});

			let countLoaded = 0;
			function afterJsonLoaded() {
				countLoaded++;
				if (countLoaded == dataFiles) {
					next((vm) => vm.setData(teamData, sectionsData));
				}
			}
		},
		methods: {
			goToVacancies() {
				this.$router.push({ path: "/nieuws", query: { userId: "123" } });
			},
			setData(teamData, sectionsData) {
				this.teamPageData = {
					partners: teamData.partners,
					team: teamData.team,
				};

				this.data_hero = sectionsData["about-us"];
				this.data_columns_list = sectionsData["mission-vision"].list;

				this.data_carrousel = sectionsData["carrousel"].images;
				this.carrousel_loaded = true;

				this.data_work = sectionsData["work-with-us"];
			},
		},
	};
</script>

<style></style>
